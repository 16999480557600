<template>
     <div class="container-fluid">
         <SuccessAlert ref="MySuccessAlert" :title="AlertTitle" :message="AlertMessage" :back="true"/>
          <div class="row row-custom">
            <div class="col-md-8">
              <div class="card">
                <div class="card-header card-header-primary">
                  <h4 class="card-title">تعديل مرفق</h4>
                 </div>
                <div class="card-body">
                    <div class="row mb-3">
                        <InputToggle @GiveMeIfo="HandleInputToggle('is_main',$event)" v-model="form.is_main" :MyValue="form.is_main"  id="is_main" title="رئيسية" :errors="service_error" />
                        <Input id="sort" title="الترتيب" @Input="HandleInput('sort',$event)" Mykey="sort" :errors="service_error" :MyValue="form.sort"/>
                        <InputFile id="img_path" title="الصورة" @Input="HandleInput('img_path',$event)" Mykey="img_path"  :errors="service_error" />
                    </div>

                     <button type="button" class="btn btn-primary pull-right"   @click="onUpdate()">تعديل </button>
                     <div class="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
</template>

<script>
import Input from '../../../../components/Form/TextInput'
 import InputFile from '../../../../components/Form/InputFile'
  import {mapState,mapMutations} from 'vuex';
import store from '../../../../store/index'
import SuccessAlert from '../../../../components/SuccessAlert'
import InputToggle from '../../../../components/Form/InputToggle'
export default {
  computed:{
    ...mapState({
        Category:state=>state.admin.categories.Category.data,
        service_error:state=>state.admin.categories.Category.errors,

    }),

    isNew(){
      return this.form.uuid == '';
    },
 
   },
   data(){
       return{
           form:{
               id:'',
               is_main:'',
               sort:'',
               img_path:'',
           },
           AlertTitle:'',
           AlertMessage:'',
 
       }
   },
   methods:{
    ...mapMutations({
          cleanErrors: "admin/cleanCategoriesErrors"
       }),

       onUpdate(){
     //    this.isLoading=true;
          store.commit('admin/PleaseStartLoading');        
          let formData=new FormData(); 
          for (let key in this.form) {
            formData.append(key, this.form[key]);
          }
          formData.append('_method', "patch");
          store.commit('admin/PleaseStartLoading');
          store.dispatch('admin/updateProductAttachment',formData).then((response)=>{
             store.commit('admin/PleaseStopLoading');
            this.cleanErrors();
            this.AlertTitle="معلومات",
            this.AlertMessage="تم حفظ التغيرات بنجاح";
            this.$refs.MySuccessAlert.showModel();
          }).catch((error)=>{
          store.commit('admin/PleaseStopLoading');
          });
       },
       HandleInputToggle(key,value){
         this.form[key]=value.val;
     },
  
     HandleInput(key,value){
         this.form[key]=value;
     },
 
     HandleSelect(key,value){
         this.form[key]=value;
     },
   },
   components:{
       Input,
       SuccessAlert,
       InputFile,
       InputToggle
    },
   
     beforeRouteEnter(to,from,next){  
      //   store.commit('admin/PleaseStartLoading');
        const id = to.params.id; 

        store.dispatch('admin/fetchProductAttachment', id).then((response) => {
                next(vm => {
                    vm.form.id=response.data.data.id;
                    vm.form.is_main=response.data.data.is_main;
                    vm.form.sort=response.data.data.sort;
                });
            });
    },
      beforeDestroy(){
         this.cleanErrors();
     }
}
</script>

<style lang="scss">
/*
 *
 */
.row-custom{
    justify-content: center;
}


















</style>